import {
  Mapbox,
  Sidebar,
  useAdminContext
} from '../';
import { isNotEmpty } from '../../utils';
import './Admin.scss';

const Admin = () => {
  const { locationData, locationError, locationLoading, activityData, activityError, activityLoading } = useAdminContext();
  const isLoading = locationLoading || activityLoading;
  const isError = locationError || activityError;
  const hasData = isNotEmpty(locationData) && isNotEmpty(activityData);

  if (isLoading) {
    return (
      <div className="admin">
        <h1 style={{ textAlign: 'center' }}>Loading...</h1>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="admin">
        <h1 style={{ textAlign: 'center' }}>Something went wrong</h1>
      </div>
    );
  }

  if (!isLoading && hasData) {
    return (
      <div id="admin">
        <Sidebar />
        <Mapbox data={locationData} />
      </div>
    );
  }
};

export default Admin;
