import { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../hooks';
import { isNotEmpty } from '../../utils';

const LOCATION_URL = process.env.REACT_APP_LOCATION_API_URL;

const AdminContext = createContext([{}, () => { }]);

export const AdminContextProvider = ({ children }) => {
  const [viewState, setViewState] = useState({
    longitude: null,
    latitude: null,
    zoom: null
  });
  const { locationId } = useParams();
  const { status: locationStatus, loading: locationLoading, error: locationError, data: locationData } = useFetch(`${LOCATION_URL}/${locationId}`);
  const { status: activityStatus, loading: activityLoading, error: activityError, data: activityData } = useFetch(`${LOCATION_URL}/${locationId}/activity`);

  useEffect(() => {
    if (isNotEmpty(locationData)) {
      setViewState({
        longitude: locationData?.coordinates?.lon,
        latitude: locationData?.coordinates?.lat,
        zoom: 19
      });
    }
  }, [locationData]);

  return (
    <AdminContext.Provider
      value={{
        viewState,
        setViewState,
        locationId,
        locationLoading,
        locationStatus,
        locationError,
        locationData,
        activityLoading,
        activityStatus,
        activityError,
        activityData
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  const adminContext = useContext(AdminContext);

  if (!adminContext) {
    throw new Error(
      'No AdminContextProvider found when calling useAdminContext.'
    );
  }

  return adminContext;
};
