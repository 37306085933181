import axios from 'axios';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { Cookies } from 'react-cookie';
import { getTime } from 'date-fns';
export * from './rbac';

const cookies = new Cookies();

const ajax = axios.create({
  baseURL: process.env.REACT_APP_CORE_USER_API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

// check if array or object is empty
export function isNotEmpty(array) {
  return !_.isEmpty(array);
}

// get cookie by name
export function getCookie(name) {
  return cookies.get(name);
}

// update auth cookie
export function refreshSession() {
  const value = getCookie('86553b39');

  if (value && value !== 'undefined') {
    return ajax.post('authenticate?refreshCookie=true');
  } else {
    console.error(`Can not refresh session because current cookie expired`);
  }
}

// Sign out user and redirect to suite login
export function signOut(params = '') {
  return window.location.replace(`${process.env.REACT_APP_SUITE_URL + 'sign-out' + params}`);
}

function authorizationCheck(authCookie) {
  return getAuthUser(authCookie).g.filter(rel => rel.r === 'GLOBAL_ADMIN').length > 0;
}

function checkForEnvMatch() {
  return getAuthUser().ne === process.env.REACT_APP_ENV_TAG;
}

export const checkAuth = () => {
  const authCookie = getCookie('86553b39');

  if (authCookie && checkForEnvMatch() && authorizationCheck(authCookie)) {
    return true;
  } else {
    return false;
  }
};

export function getAuthUser() {
  return jwtDecode(getCookie('86553b39'), { complete: true }).payload;
}

export function getPermissions() {
  return jwtDecode(getCookie('perm')).payload;
}

export function getSessionTime() {
  const iat = jwtDecode(getCookie('86553b39'), { complete: true }).iat;
  return Math.abs((new Date(iat * 1000) - new Date()) / 1000 / 60);
}

export function ISOtoMilli(isoDate) {
  return getTime(new Date(isoDate));
};

export const userTimezoneName = new Intl.DateTimeFormat('default', { timeZoneName: 'short' })
  .format(new Date())
  .split(',')[1]
  .padStart();

export const minutesToMilli = (minutes = 0) => minutes * 60000;

export const userTimezoneOffsetMilli = minutesToMilli(new Date().getTimezoneOffset());
