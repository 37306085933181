import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Configuration } from 'react-md';
import { App } from './components';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Configuration>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Configuration>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
