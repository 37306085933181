import { useEffect, useRef, useReducer } from 'react';

const useFetch = (url) => {
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    loading: false,
    error: null,
    data: null
  };

  const [state, dispatch] = useReducer((_state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching', loading: true };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', loading: false, data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', loading: false, error: action.payload };
      default:
        return _state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;
    if (!url || !url.trim()) { return; }

    const fetchData = async () => {
      dispatch({ type: 'FETCHING' });

      if (cache.current[url]) {
        const data = cache.current[url];
        dispatch({ type: 'FETCHED', payload: data });

      } else {
        try {
          const response = await fetch(url);
          const data = await response.json();
          cache.current[url] = data;

          if (cancelRequest) { return; }
          dispatch({ type: 'FETCHED', payload: data });

        } catch (error) {
          console.error(error);

          if (cancelRequest) { return; }
          dispatch({ type: 'FETCH_ERROR', payload: error.message });
        }
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [url]);

  return state;
};

export default useFetch;
