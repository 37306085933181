import { useState } from 'react';
import { upperFirst, lowerCase } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import './SidebarTable.scss';

import {
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody
} from 'react-md';

const sort = (list, key, ascending) => {
  const sorted = list.slice();
  sorted.sort((a, b) => {
    const aValue = a[key];
    const bValue = b[key];

    const value =
      typeof aValue === 'number'
        ? aValue - bValue
        : aValue.localeCompare(bValue);

    return value * (ascending ? 1 : -1);
  });

  return sorted;
};

export default function SidebarTable({ list, defaultKey = 'name' }) {
  const columns = Object.keys(list[0]);

  const [state, setState] = useState(() => ({
    data: sort(list, defaultKey, true),
    sortKey: defaultKey,
    sortOrder: 'ascending'
  }));

  const updateSort = (sortKey) => {
    setState((prevState) => {
      const prevSortKey = prevState.sortKey;
      const prevSortOrder = prevState.sortOrder;

      let sortOrder;
      if (sortKey === prevSortKey) {
        // it's the same column, so toggle the sort order
        sortOrder = prevSortOrder === 'ascending' ? 'descending' : 'ascending';
      } else {
        // it's a new column to sort by, so default to ascending for the name column
        // but descending for all the rest.
        sortOrder = sortKey === defaultKey ? 'ascending' : 'descending';
      }

      return {
        data: sort(list, sortKey, sortOrder === 'ascending'),
        sortKey,
        sortOrder
      };
    });
  };

  const formatCell = (value, key) => {
    if (key === 'timeParked') {
      return formatDistanceToNow(new Date(value), { addSuffix: true });
    } else {
      return value;
    }
  };

  const { data, sortKey, sortOrder } = state;
  return (
    <TableContainer className="sidebar-table-container">
      <Table fullWidth>
        <TableHeader sticky>
          <TableRow>
            {columns.map((name) => (
              <TableCell
                key={name}
                aria-sort={name === sortKey ? sortOrder : 'none'}
                onClick={() => updateSort(name)}
                lineWrap
                className="sidebar-table-th"
              >
                {upperFirst(lowerCase(name))}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableRow
              disableBorders
              key={`${item.timeParked}-${index}`}
              onClick={() => console.log(item)}
            >
              {columns.map((key) => (
                <TableCell
                  key={key}
                  lineWrap
                  className="sidebar-table-td"
                >
                  {formatCell(item[key], key)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
