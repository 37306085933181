import React from 'react';

const style = {
  cursor: 'pointer',
  fill: '#d00',
  stroke: 'none'
};

function CircleMarker({ size = 12, color = '#AED580' }) {
  return (
    <svg height={size} viewBox="0 0 24 24" style={style}>
      <circle cx={size} cy={size} r={size} fill={color} stroke="#fff" strokeWidth={1} />
    </svg>
  );
}

export default React.memo(CircleMarker);
