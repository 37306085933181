import { useAdminContext } from '../';
import cx from 'classnames';
import './LocationTree.scss';

const LocationTree = ({ tree }) => {
  const { setViewState } = useAdminContext();

  const handleLocationClick = (location) => {
    console.log('location: ', location);

    setViewState({ latitude: location.lat, longitude: location.lon, zoom: 19 });
  };

  const formatTree = (location) => {
    const list = [];
    list.push({ name: location?.name, id: location?.id, count: location?.lots?.length, coordinates: location?.coordinates, type: 'landmark' });

    location?.lots?.forEach(lot => {
      list.push({ name: lot?.name, id: lot?.id, count: lot?.total_spots, coordinates: lot?.coordinates, type: 'lot' });

      lot?.spots?.forEach(spot => {
        list.push({ name: spot?.name, id: spot?.id, count: null, coordinates: spot?.coordinates, type: 'spot' });
      });
    });

    return list;
  };

  const branches = formatTree(tree);

  return (
    <div className="location-tree">
      {branches.map(branch => (
        <div
          key={branch?.id}
          className={cx('branch', `branch-${branch?.type}`)}
          onClick={() => handleLocationClick(branch?.coordinates)}
        >
          <div className="branch-name">
            {branch?.name}
          </div>
          <div className="branch-count">
            {branch?.count} {branch?.type === 'landmark' ? 'lots' : branch?.type === 'lot' ? 'spots' : ''}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LocationTree;
