import Highcharts from 'highcharts';
import { Chart, HighchartsChart, Tooltip, HighchartsProvider, XAxis, YAxis, AreaSeries } from 'react-jsx-highcharts';
import applyAccessibility from 'highcharts/modules/accessibility';
applyAccessibility(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const LineChart = ({ data: chartData = [] }) => {
  const markerOptions = {
    fillColor: '#FFFFFF',
    lineWidth: 2,
    lineColor: null,
    symbol: 'circle'
  };

  const chartLines = () =>
    chartData.map(series => {
      const { color = '', data: seriesData = [], name = '' } = series;
      const key = `${name}-series`;

      return (
        <AreaSeries key={key} name={name} color={color} marker={markerOptions} data={seriesData} lineWidth={2} />
      );
    });

  return (
    <HighchartsProvider Highcharts={Highcharts}>
      <HighchartsChart
        chart={{
          height: '200px'
        }}
        className="line-chart"
      >
        <Chart />
        <Tooltip backgroundColor="white" xDateFormat="%a., %b %e, %l:%M%P" />
        <XAxis
          type="datetime"
          tickInterval={3600 * 1000}
          dateTimeLabelFormats={{
            day: {
              main: '%e %b %y'
            },
            hour: {
              main: '%l %P'
            },
            minute: {
              main: '%l:%M %P'
            }
          }}
          labels={{
            style: {
              color: '#666666',
              'font-size': '14px'
            }
          }}
        />
        <YAxis
          gridLineWidth="0px"
          labels={{
            enabled: false
          }}
        >
          {chartLines()}
        </YAxis>
      </HighchartsChart>
    </HighchartsProvider>
  );
};

export default LineChart;
