import { ExpansionList, ExpansionPanel, usePanels } from 'react-md';
import { useAdminContext, SidebarTable, LocationTree, ActivityChart } from '../';
import { ISOtoMilli } from '../../utils';
import Odometer from 'react-odometerjs';
import './Sidebar.scss';
import 'odometer/themes/odometer-theme-default.css';

const Sidebar = () => {
  const { locationData, activityData } = useAdminContext();
  const activityTimeSeries = activityData.map(({ timeParked }, index) => ({
    key: ISOtoMilli(timeParked),
    value: activityData.length - index
  })
  );

  const [panels, onKeyDown] = usePanels({
    idPrefix: 'sidebar-panels',
    count: 2,
    defaultExpandedIndex: [0, 1],
    multiple: true
  });

  const [panel1Props, panel2Props] = panels;

  return (
    <aside className="sidebar-container">
      <ExpansionList onKeyDown={onKeyDown}>
        <ExpansionPanel
          {...panel1Props}
          header="Recent Parkers"
          className="sidebar-panel"
          disablePadding
          headerClassName="sidebar-header"
        >
          <div className="sidebar-hero">
            <div className="sidebar-metric-chart">
              <ActivityChart data={activityTimeSeries} />
            </div>
            <hr className="sidebar-divider" />
            <div className="sidebar-metrics">
              <div className="sidebar-metric">
                <div className="sidebar-metric-value">
                  <Odometer duration={100} value={6} format="(,ddd)" />
                </div>
                <div className="sidebar-metric-label">
                  Total Spots
                </div>
              </div>
              <div className="sidebar-metric">
                <div className="sidebar-metric-value">
                  <Odometer duration={100} value={5} format="(,ddd)" />
                </div>
                <div className="sidebar-metric-label">
                  <div className="sidebar-metric-label-legend" style={{ backgroundColor: '#8bc34a' }} />
                  <div className="sidebar-metric-label-text">
                    Total Parked
                  </div>
                </div>
              </div>
              <div className="sidebar-metric">
                <div className="sidebar-metric-value">
                  <Odometer duration={100} value={1} format="(,ddd)" />
                </div>
                <div className="sidebar-metric-label">
                  <div className="sidebar-metric-label-legend" style={{ backgroundColor: '#efefef' }} />
                  <div className="sidebar-metric-label-text">
                    Available spots
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SidebarTable
            list={activityData}
            defaultKey="timeParked"
          />
        </ExpansionPanel>
        <ExpansionPanel
          {...panel2Props}
          header="Locations"
          className="sidebar-panel"
          disablePadding
          headerClassName="sidebar-header"
        >
          <LocationTree tree={locationData} />
        </ExpansionPanel>
      </ExpansionList>
    </aside>
  );
};

export default Sidebar;
