import { useState, useMemo, useCallback } from 'react';
import Map, { Marker, Popup } from 'react-map-gl';
import { useAdminContext } from '../';
import CircleMarker from './CircleMarker';
import PlaceMarker from './PlaceMarker';
import { formatDistanceToNow, format } from 'date-fns';
import style from './Mapbox.module.scss';
import 'mapbox-gl/dist/mapbox-gl.css';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_KEY;

export default function MapBox() {
  const { viewState, setViewState, locationData } = useAdminContext();
  const [popupInfo, setPopupInfo] = useState(null);

  const onMove = useCallback(evt => setViewState(evt.viewState), [setViewState]);

  const formatTree = (location) => {
    const list = [];
    list.push({ name: location?.name, id: location?.id, coordinates: location?.coordinates, type: 'landmark' });

    location?.lots?.forEach(lot => {
      list.push({ name: lot?.name, id: lot?.id, coordinates: lot?.coordinates, type: 'lot' });

      lot?.spots?.forEach(spot => {
        list.push({ name: spot?.name, id: spot?.id, coordinates: spot?.coordinates, type: 'spot', updated: spot?.updated, occupied: spot?.occupied });
      });
    });

    return list;
  };

  const pins = useMemo(
    () =>
      formatTree(locationData).map((item, index) => (
        <Marker
          key={`marker-${item?.id}`}
          longitude={item.coordinates.lon}
          latitude={item.coordinates.lat}
          anchor="bottom"
          onClick={e => {
            e.originalEvent.stopPropagation();
            setPopupInfo(item);
          }}
        >
          {item.type === 'spot' && <CircleMarker color={item?.occupied ? '#AED580' : '#FF7373'} />}
          {(item.type === 'lot' || item.type === 'landmark') && <PlaceMarker text={item.name} />}
        </Marker>
      )),
    [locationData]
  );

  return (
    <div className={style.container}>
      <Map
        initialViewState={{
          latitude: locationData.coordinates.lat,
          longitude: locationData.coordinates.lon,
          zoom: 19
        }}
        {...viewState}
        onMove={onMove}
        reuseMaps
        mapStyle="mapbox://styles/novym/cl98u4tfw001d15s1k5mruxoy"
        mapboxAccessToken={MAPBOX_TOKEN}
      >
        {pins}
        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.coordinates.lon)}
            latitude={Number(popupInfo.coordinates.lat)}
            onClose={() => setPopupInfo(null)}
          >
            <div>
              <h3 className={style.popupTitle}>{popupInfo.name}</h3>
              {popupInfo?.updated && (
                <div>Parked {formatDistanceToNow(new Date(popupInfo.updated))} at {format(new Date(popupInfo.updated), 'h:m aaa')}</div>
              )}
            </div>
          </Popup>
        )}
      </Map>
    </div>
  );
}
