import React from 'react';

const labelStyle = {
  backgroundColor: '#fff',
  textAlign: 'center',
  padding: '.25em',
  opacity: .5
};

function LabelMarker({ text }) {
  return (
    <svg height="24" viewBox="0 0 80 24" style={labelStyle}>
      <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
        {text}
      </text>
    </svg>
  );
}

export default React.memo(LabelMarker);
