import { some, isEmpty } from 'lodash';
import { getPermissions, getAuthUser } from '.';

export function getFeatureAccess() {
  const perm = getPermissions();
  const authUser = getAuthUser();
  const permissions = perm?.permissions;
  const isGlobalAdmin = getAuthUser().g.map(o => o.r).includes('GLOBAL_ADMIN');
  const isGhost = !!perm?.gk || (!!authUser?.gk && isGlobalAdmin);

  function checkAppAccess(path, scope) {
    return some(permissions, { path, scope });
  }

  function createAccess() {
    const isFullAccess = checkAppAccess('suite', 'WRITE');

    if (isFullAccess) {
      return {
        ghosting: true,
        vips: true,
        live: true,
        analytics: true,
        devices: true,
        api: true,
        portal: true,
        education: true,
        scheduler: true,
        prepaid_pass_manager: true,
        provisioning: true,
        users: true,
        signal: true,
        network_diagram: true,
        freeflow: true
      };

    } else {
      const paths = permissions
        ?.map(permission => ({ [permission.path.split('suite.')[1]]: true }))
        ?.reduce((acc, v) => {
          return { ...acc, ...v };
        }, {});

      paths.ghosting = isGhost;

      return paths;
    }
  }

  // Temporary solution until RBAC in implemented across all environments
  function createTempAccess() {
    return {
      ghosting: isGhost,
      vips: true,
      live: true,
      analytics: true,
      devices: true,
      api: true,
      portal: true,
      education: true,
      scheduler: isGlobalAdmin,
      prepaid_pass_manager: isGlobalAdmin,
      provisioning: isGlobalAdmin,
      users: isGlobalAdmin,
      signal: isGlobalAdmin,
      network_diagram: isGlobalAdmin,
      freeflow: true
    };
  }

  if (isEmpty(permissions)) {
    return createTempAccess();

  } else {
    return createAccess();
  }
};
